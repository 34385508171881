<template>
	<div class="page">
		<div class="searchbar">
			<el-form ref="searchForm" :model="searchFormData" :inline="true" :show-message="false">
				<!--自定义搜索内容 -->
				<el-form-item>
<!--					<el-select v-model="searchFormData.group_id" placeholder="小区" clearable>-->
<!--						<el-option v-for="group in groupList" :key="group.group_id" :label="group.group_name" :value="group.group_id"></el-option>-->
<!--					</el-select>-->
					<el-select
							v-model="searchFormData.group_id"
							filterable
							reserve-keyword
							clearable
							placeholder="小区"
							:remote-method="remoteGroup"
							:loading="groupLoading">
						<el-option
								v-for="item in groupList"
								:key="item.group_id"
								:label="item.group_name"
								:value="item.group_id">
						</el-option>
					</el-select>
				</el-form-item>
				<!--搜索-->
				<el-form-item>
					<el-button type="primary" @click="onSearch" size="small" icon="el-icon-search" plain>查询</el-button>
				</el-form-item>
				<!--重置-->
				<el-form-item>
					<el-button @click="resetForm" size="small" icon="el-icon-refresh" plain>重置</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="resultswrap">
			<el-row :gutter="20">
				<el-col :span="8">
					<el-card header="订单统计">
						<el-form>
							<el-form-item label="订单总数：">{{ resultNum.all_num ? resultNum.all_num : '--' }}</el-form-item>
							<el-form-item label="微信消费订单数量：">{{ resultNum.all_order_num ? resultNum.all_order_num : '--' }}</el-form-item>
							<el-form-item label="卡消费订单数量：">{{ resultNum.all_card_num ? resultNum.all_card_num : '--' }}</el-form-item>
							<el-form-item label="微信充值订单数量：">{{ resultNum.all_recharge_money_num ? resultNum.all_recharge_money_num : '--'}}</el-form-item>
							<el-form-item label="卡充值订单数量：">{{ resultNum.all_recharge_card_num ? resultNum.all_recharge_card_num : '--' }}</el-form-item>
						</el-form>
					</el-card>
				</el-col>
				<el-col :span="8">
					<el-card header="金额统计">
						<el-form>
							<el-form-item label="累计收入总金额：">{{ resultMoney.all_money ? resultMoney.all_money : '--' }}元</el-form-item>
							<el-form-item label="充电订单总金额：">{{ resultMoney.all_order_money ? resultMoney.all_order_money : '--' }}元</el-form-item>
							<el-form-item label="临时充电订单金额：">{{ resultMoney.all_order_short_money ? resultMoney.all_order_short_money : '--' }}元</el-form-item>
							<el-form-item label="会员充电订单金额：">{{ resultMoney.all_order_member_money ? resultMoney.all_order_member_money : '--'}}元</el-form-item>
							<el-form-item label="充值订单总金额：">{{ resultMoney.all_recharge_money ? resultMoney.all_recharge_money : '--'}}元</el-form-item>
							<el-form-item label="微信充值订单金额：">{{ resultMoney.all_recharge_wx_money ? resultMoney.all_recharge_wx_money : '--' }}元</el-form-item>
							<el-form-item label="卡充值订单金额：">{{ resultMoney.all_recharge_card_money ? resultMoney.all_recharge_card_money : '--' }}元</el-form-item>
							<el-form-item label="现金收入：">{{ resultMoney.cash_money ? resultMoney.cash_money : '--' }}元</el-form-item>
							<el-form-item label="卡实际收入：">{{ resultMoney.card_reality_money ? resultMoney.card_reality_money : '--' }}元</el-form-item>
							<el-form-item label="充值退款：">{{ resultMoney.recharge_refund ? resultMoney.recharge_refund : '--' }}元</el-form-item>
							<el-form-item label="充电退款：">{{ resultMoney.charge_refund ? resultMoney.charge_refund : '--' }}元</el-form-item>
						</el-form>
					</el-card>
				</el-col>
				<el-col :span="8">
					<el-card header="小区信息">
						<el-form>
							<el-form-item label="用电量：">{{(resultInfo.electric ? resultInfo.electric : '--') + ' kW·h'}}</el-form-item>
							<el-form-item label="开始时间：">{{ resultInfo.start_time ? resultInfo.start_time : '--' }}</el-form-item>
							<el-form-item label="完工时间：">{{ resultInfo.finish_time ? resultInfo.finish_time : '--' }}</el-form-item>
							<el-form-item label="统计截止时间：">{{ resultInfo.end_time ? resultInfo.end_time : '--' }}</el-form-item>
						</el-form>
					</el-card>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'report-group-examine',
		data() {
			return {
				searchFormData: {},
				groupList: [],
				resultNum: {},
				resultMoney: {},
				resultInfo: {},
				types: {
					model: {
						10: '在线卡',
						20: '离线卡'
					},
					type: {
						10: '金额卡',
						20: '次卡',
						30: '包年包月'
					},
					btype: {
						10: '元',
						20: '次',
						30: ''
					}
				}
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			// 初始化请求
			init() {
				this.getGroupList()
			},
			// 获取小区信息
			async getGroupList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 1000
				}
				const res = await this.$api.Device.GetGroupList(params, {})
				this.groupList = res.data
			},
			// 表格列表
			async getResults() {
				const params = {
					token: this.$store.state.user.token,
					form: JSON.stringify(this.searchFormData)
				}
				const loading = this.$loading({
					lock: true
				})
				const res = await this.$api.Report.GetGroupExamine(params)
				this.resultNum = res.num;
				this.resultMoney = res.money;
				this.resultInfo = res.info;
				loading.close()
			},
			// 小区
			async remoteGroup (query) {
				if (query !== '') {
					this.groupLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const res = await this.$api.Device.GetGroupList(params, {
						group_name: query
					})
					this.groupList = res.data
					this.groupLoading = false
				} else {
					this.groupList = []
				}
			},
			// 重置搜索
			resetForm() {
				this.searchFormData = {};
				this.datas = [];
				this.resultNum = {};
				this.resultMoney = {};
				this.resultInfo = {};
			},
			// 查询
			onSearch() {
				if (!this.searchFormData.group_id) {
					this.$message.warning('请选择要查询的小区')
					return;
				}
				this.getResults();
			}
		}
	}
</script>
<style lang="scss" scoped>
	.searchbar {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		position: relative;
		min-height: 40px;
		flex-shrink: 0;
	}

	.resultswrap {
		margin: 10px 0;
		flex-grow: 1;
	}
</style>
